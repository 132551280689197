.greeting {
  background: #eeeeee;
  background-image: url(https://dmse.mit.edu/themes/custom/dmsed8/images/home/hexbg_home_body_2580x1560_tinypng.png);
  background-position: bottom;
}

h1 {
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
}

table {
  margin: 0 !important;
}

td {
  padding: 0.25rem !important;
  vertical-align: middle;
}

.hexagon-container {
  overflow: visible;
}

.hexagon {
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  transform: rotate(30deg);
  margin: -3% 6%;
  transition: all cubic-bezier(0.8, 0, 0, 0.8) 1s;
}

.hexagon:hover {
  transform: rotate(25deg) scale(1.05);
}

.hexagon img {
  max-width: 100%;
}

li {
  margin-bottom: 0.5rem;
}

.flex-reverse {
  flex-direction: row-reverse;
}
