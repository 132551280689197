#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body div {
  font-family: 'Rajdhani', sans-serif;
  font-size: 1.2rem;
}

main {
  flex-grow: 1;
}

footer {
  background: #eeeeee;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
}

h1, h2, h3, h4, h5 {
  font-family: 'Fira Sans', sans-serif;
}

@counter-style arrow {
  system: cyclic;
  symbols: "❯";
  suffix: " ";
}

ul {
  list-style: arrow;
}
