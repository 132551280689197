.course-card {
    color: white;
    width: initial;
    min-width: 10rem;
    max-width: 20rem;
    border-radius: 0;
    margin: 0.5rem;
}

.course-card .card-subtitle {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.course-card .card-body {
    padding: 1rem 0 !important;
}

@media screen and (max-width: 540px) {
    .course-card {
        width: 95%;
        max-width: 95%;
    }
}

.card-title {
    font-family: 'Fira Sans', sans-serif;
}
