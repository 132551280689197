h2 {
  font-weight: bold;
}

.heading {
  position: relative;
  display: inline-block;
  text-align: center;
}

.heading-xs h2 {
  font-size: 1.5rem;
  text-shadow: 0.1rem 0.1rem black;
}
.heading-sm h2 {
  font-size: 2rem;
  text-shadow: 0.15rem 0.15rem black;
}
.heading-md h2 {
  font-size: 3rem;
  text-shadow: 0.175rem 0.175rem black;
}
.heading-lg h2 {
  font-size: 4rem;
  text-shadow: 0.225rem 0.225rem black;
}
.heading-xl h2 {
  font-size: 6rem;
  text-shadow: 0.375rem 0.375rem black;
}

.heading-mask {
  position: absolute;
  top: 0.05rem;
  left: 0.05rem;
  transition: all cubic-bezier(1, 0, 0, 1) 1s;
  cursor: pointer;
}

.heading-hidden {
  margin: 0.25rem;
  display: hidden;
}

.heading-rect {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all cubic-bezier(1, 0, 0, 1) 1s;
}
